import { createContext, useCallback, useReducer } from 'react'

const initialState = {
    provider: null,
    web3Provider: null,
    address: null,
    chainId: null,
    signer: null,
}

function reducer(state, action) {
    switch (action.type) {
        case 'reset':
            return {
                ...initialState,
            }
        case 'update':
            return {
                ...state,
                [action.key]: action.value,
            }
        case 'multiUpdate':
            return {
                ...state,
                ...action.state,
            }
        default:
            throw new Error()
    }
}

const walletStore = createContext({
    state: initialState,
    dispatch: () => {},
})

const { Provider } = walletStore

const WalletStateProvider = ({ children, values = {} }) => {
    const resetState = useCallback(
        () => ({
            ...initialState,
            ...values,
        }),
        [values]
    )

    const [state, dispatch] = useReducer(reducer, resetState())

    return <Provider value={{ state, dispatch }}>{children}</Provider>
}

export { walletStore, WalletStateProvider }
