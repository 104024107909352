/**
 *
 * @return Promise
 */

/**
 * Handles the action to be performed
 * when the "Connect" button is clicked.
 *
 * @param event https://developer.mozilla.org/en-US/docs/Web/API/Element/click_event
 */

export const getPriceVnyVsUsd = async (): Promise<any> => {
    const url = 'https://api.coingecko.com/api/v3/simple/price?ids=vanity&vs_currencies=usd'
    try {
        const result = await fetch(url, {
            headers: {
                'Content-Type': 'application/json',
            },
        })
        return result.json()
    } catch (error) {
        console.log('usePriceVnyVsUsd ==>', error)
    }

    return undefined
}
