import { useCallback, useContext } from 'react'
import Address from 'components/Address/Address'
import WalletConnectProvider from '@walletconnect/web3-provider'
import { providers } from 'ethers'
import Web3Modal from 'web3modal'
import Torus from '@toruslabs/torus-embed'
import { walletStore } from '../../context/Wallet'
import { toast } from 'react-toastify';

const providerOptions = {
    walletconnect: {
        package: WalletConnectProvider, // required
        options: {
            rpc: {
                56: 'https://dry-smart-meadow.bsc.discover.quiknode.pro/7ce5ddb62dd32c7955bfcce2ea2b420b1d0965ae/'       
             },
            network: 'binance',
            infuraId: '34dbdf77d1374a9db4e1b4074f949cee', // required
        },
    },
    torus: {
        package: Torus, // required
    },
}

let web3Modal
if (typeof window !== 'undefined') {
    web3Modal = new Web3Modal({
        cacheProvider: true,
        providerOptions, // required
    })
}

const styles = {
    account: {
        height: '42px',
        padding: '0 15px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: 'fit-content',
        borderRadius: '12px',
        backgroundColor: 'rgb(244, 244, 244)',
        cursor: 'pointer',
    },
    text: {
        color: '#21BF96',
    },
    connector: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        justifyContent: 'center',
        marginLeft: 'auto',
        marginRight: 'auto',
        padding: '20px 5px',
        cursor: 'pointer',
    },
    icon: {
        alignSelf: 'center',
        fill: 'rgb(40, 13, 95)',
        flexShrink: '0',
        marginBottom: '8px',
        height: '30px',
    },
}

function Account() {
    const { state, dispatch } = useContext(walletStore)
    const { provider, address } = state

    const connect = useCallback(async function () {
        try {
            const provider = await web3Modal.connect()
            const web3Provider = new providers.Web3Provider(provider)
            const signer = web3Provider.getSigner()
            const address = await signer.getAddress()
            const network = await web3Provider.getNetwork();
            provider.on('error', (error) => {
                console.log('error ==> ', error)
            })

            if (![56, '0x38'].includes(network.chainId)) {
                toast.warn('Please connect to the BSC Mainnet')
            }

            provider.on('chainChanged', async (chainId) => {
                if ([56, '0x38'].includes(chainId)) {
                    const updatedProvider = await web3Modal.connect()
                    const updatedWeb3Provider = new providers.Web3Provider(updatedProvider)
                    const updatedSigner = updatedWeb3Provider.getSigner(0)
                    dispatch({
                        type: 'multiUpdate',
                        state: {
                            chainId: chainId,
                            signer: updatedSigner,
                        },
                    })
                } else {
                    toast.warn('Please connect to the BSC Mainnet')
                }
            })
            provider.on('accountsChanged', async (newAddress) => {
                const updatedProvider = await web3Modal.connect()
                const updatedWeb3Provider = new providers.Web3Provider(updatedProvider)
                const updatedSigner = updatedWeb3Provider.getSigner(0)
                dispatch({
                    type: 'multiUpdate',
                    state: {
                        address: newAddress[0],
                        signer: updatedSigner,
                    },
                })
            })
            dispatch({
                type: 'multiUpdate',
                state: {
                    provider,
                    web3Provider,
                    address,
                    chainId: network.chainId,
                    signer: signer,
                },
            })
        } catch (e) {
            console.log('Error connect ---->', e)
        }
    }, [dispatch])

    const disconnect = useCallback(
        async function () {
            try {
                await web3Modal.clearCachedProvider()
                if (provider && provider.disconnect && typeof provider.disconnect === 'function') {
                    await provider.disconnect()
                }
                dispatch({
                    type: 'reset',
                })
            } catch (e) {
                console.log('Error disconnect ---->', e)
            }
        },
        [provider,dispatch]
    )

   

    if (!address) {
        return (
            <div onClick={connect}>
                <p style={styles.text}>Connect Wallet</p>
            </div>
        )
    }

    return (
        <>
            <Address avatar="left" size={6} copyable style={{ fontSize: '20px' }} />
            <div onClick={disconnect}>
                <p style={styles.text}>Disconnect Wallet</p>
            </div>
        </>
    )
}

export default Account
