import { Container } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { Link } from 'react-router-dom'

// --- Footer ---
const Footer = () => (
    <Container className="text-center footer">
        <Link className="footer-link" to="/tos">
            <FormattedMessage id="footer.link.tos" />
        </Link>
    </Container>
)

export default Footer
