import ReactDOM from 'react-dom'

// Sass
import 'styles/main.scss'

// Utils
import PageTitle from 'utils/PageTitle'

// Components
import Providers from 'Providers'

// Router
import AppRouter from './AppRouter'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

//localStorage.clear();


// --- Root ---

const Root = () => (
    <Providers>
        <PageTitle />
          <ToastContainer theme={'dark'} />
        <AppRouter />
    </Providers>
)

// Render DOM
ReactDOM.render(<Root />, document.getElementById('root'))

if (module.hot) {
    module.hot.accept()
}
