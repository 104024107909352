import { lazy, Suspense } from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'

// Utils
import { SetPageTitle } from 'utils/PageTitle'

// Layouts
import AppLayout from 'layouts/AppLayout'
import { BuyPageLayout, BuyColLayout } from 'layouts/BuyPageLayout'

// Components
import Loading from 'components/Loading'

// Pages
import ErrorPage from 'pages/error'

// Lazy Pages
const Home = lazy(() => import('pages/home'))
const Inventory = lazy(() => import('pages/inventory'))
const BuyChoose = lazy(() => import('pages/buy'))
const BuyWrapped = lazy(() => import('pages/buy/Wrapped'))
const BuyUnwrapped = lazy(() => import('pages/buy/Unwrapped'))
const MarketPlace = lazy(() => import('pages/marketplace'))
const Tos = lazy(() => import('pages/tos'))

// --- AppRouter ---
const AppRouter = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<AppLayout />}>
                    <Route
                        index
                        element={
                            <Suspense fallback={<Loading />}>
                                <SetPageTitle />
                                <Home />
                            </Suspense>
                        }
                    />
                    <Route
                        path="inventory"
                        element={
                            <Suspense fallback={<Loading />}>
                                <SetPageTitle title={{ id: 'navbar.link.inventory' }} />
                                <Inventory />
                            </Suspense>
                        }
                    />
                    <Route path="buy" element={<BuyPageLayout />}>
                        <Route
                            index
                            element={
                                <Suspense fallback={<Loading />}>
                                    <SetPageTitle title={{ id: 'navbar.link.buy' }} />
                                    <BuyChoose />
                                </Suspense>
                            }
                        />
                        <Route element={<BuyColLayout />}>
                            <Route
                                path="wrapped"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <BuyWrapped />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="unwrapped"
                                element={
                                    <Suspense fallback={<Loading />}>
                                        <BuyUnwrapped />
                                    </Suspense>
                                }
                            />
                        </Route>
                    </Route>
                    <Route
                        path="marketplace"
                        element={
                            <Suspense fallback={<Loading />}>
                                <SetPageTitle title={{ id: 'navbar.link.marketplace' }} />
                                <MarketPlace />
                            </Suspense>
                        }
                    />
                    <Route
                        path="tos"
                        element={
                            <Suspense fallback={<Loading />}>
                                <SetPageTitle title={{ id: 'footer.link.tos' }} />
                                <Tos />
                            </Suspense>
                        }
                    />
                    <Route
                        path="*"
                        element={
                            <>
                                <SetPageTitle title={{ id: 'page.error.404' }} />
                                <ErrorPage code="404" />
                            </>
                        }
                    />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}
export default AppRouter
