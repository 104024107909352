// Messages
import messagesEn from './en.json'

/**
 * This struct maintains all the
 * languages supported by the application.
 */
const i18n = {
    // Add new messages here
    en: messagesEn, // Default
}

export default i18n
