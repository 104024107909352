import BigNumber from 'bignumber.js/bignumber'
/**
 * Website title.
 * Must be the same of `public/index.html`    @ `<title>...</title>"`
 * Must be the same of `public/manifest.json` @ `short_name`
 *
 * @var String
 */
export const SITE_NAME = 'VNY Token'

/**
 * Home "Buy" button link
 *
 * @var String
 */
export const HOME_BUY_LINK =
    'https://pancakeswap.finance/swap?outputCurrency=0xAbc69f2025bDb12efcdB8fd048d240fFf943ca82'

/**
 * Marketplace "TRADE" button link
 *
 * @var String
 */
export const MARKETPLACE_LINK = 'https://lootex.io/stores/vanity/'

/**
 * Buy sub-page (Wrapped) network types.
 */
export const BUY_NETWORKS = [
    { id: 'evm', text: 'EVM', notice: null },
    { id: 'btc', text: 'Bitcoin' },
    { id: 'ltc', text: 'Litecoin' },
    { id: 'doge', text: 'Dogecoin' },
]

/**
 * Buy sub-page (Unwrapped) network types.
 */
export const MINT_NETWORKS = BUY_NETWORKS

/**
 * Base url for the axios instance (api/axios.js).
 *
 * @var String
 */

/**
 * Timeout in ms of the requests made by
 * the axios instance (api/axios.js).
 *
 * @var Number
 */

BigNumber.config({
    EXPONENTIAL_AT: 1000,
    DECIMAL_PLACES: 80,
})
