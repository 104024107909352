// Actions
import { CHANGE_SITE_TITLE } from 'store/actions/config'

// Initial state
const initState = {
    locale: navigator.language.split(/[-_]/)[0], // Browser locale
    pageTitle: null,
}

// Reducer
const config = (state = initState, action) => {
    switch (action.type) {
        case CHANGE_SITE_TITLE:
            return { ...state, pageTitle: action.value }
        default:
            return state
    }
}

export default config
