import { memo } from 'react'
import { compose } from 'redux'
import { injectIntl } from 'react-intl'

// --- ErrorPage ---
const ErrorPage = ({ code, intl }) => {
    const errorStr = intl.formatMessage({ id: `page.error.${code}` })

    return (
        <div className="d-flex flex-grow-1">
            <div className="w-100 my-auto text-center">
                <h1 style={{ fontSize: '4rem' }}>{code}</h1>
                <h3 style={{ fontSize: '2rem' }}>{errorStr}</h3>
            </div>
        </div>
    )
}

export default compose(memo, injectIntl)(ErrorPage)
