import { connect } from 'react-redux'
import { IntlProvider } from 'react-intl'

// Messages store
import i18n from 'config/i18n'

// --- IntlWrapper ---
const defaultLocale = 'en'
const IntlWrapper = ({ children, locale }) => {
    const locale_ = i18n[locale] ? locale : defaultLocale
    const messages_ = i18n[locale_]

    return (
        <IntlProvider defaultLocale={defaultLocale} locale={locale_} messages={messages_}>
            {children}
        </IntlProvider>
    )
}

export default connect((state) => ({ locale: state.config.locale }), null)(IntlWrapper)
