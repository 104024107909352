import { createStore } from 'redux'

// Reducers
import reducers from './reducers'

// --- Store ---
const store = createStore(
    reducers,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
)

export default store
