// Actions
export const CHANGE_SITE_TITLE = 1
//export const CHANGE_SITE_LOCALE = 2

// Functional actions
export const changeSiteTitle = (before = null) => ({
    type: CHANGE_SITE_TITLE,
    value: before,
})

//export const changeSiteLocale = (locale = 'en') => ({
//    type: CHANGE_SITE_LOCALE,
//    value: locale,
//})
