// Actions
import { LOGIN, LOGOUT } from 'store/actions/session'

// Initial state
const initState = {
    loggedIn: false,
    secret: null,
}

// Reducer
const session = (state = initState, action) => {
    switch (action.type) {
        case LOGIN:
            return { ...state, loggedIn: true, secret: action.value }
        case LOGOUT:
            return initState
        default:
            return state
    }
}

export default session
