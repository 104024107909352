import { useEffect } from 'react'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'

// Config
import { SITE_NAME } from 'config'

// Store action
import { changeSiteTitle } from 'store/actions/config'

// --- SetPageTitle (utility) ---
const SetPageTitle_ = ({ title = null, changeTitle }) => {
    // eslint-disable-next-line
    useEffect(() => changeTitle(title), [title])
    return null
}

export const SetPageTitle = connect(null, { changeTitle: changeSiteTitle })(SetPageTitle_)

// --- PageTitle ---
const PageTitle = ({ title, intl }) => {
    if (title) {
        if ('object' === typeof title) title = intl.formatMessage(title)
        document.title = `${title} − ${SITE_NAME}`
    } else {
        document.title = SITE_NAME
    }

    return null
}

export default compose(
    connect(
        (state) => ({
            title: state.config.pageTitle,
        }),
        null
    ),
    injectIntl
)(PageTitle)
