import { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'
import { Navbar as BNavbar, Nav, Container } from 'react-bootstrap'
import { FormattedMessage, FormattedNumber } from 'react-intl'
import Account from './Account/Account'

// Api
import { getPriceVnyVsUsd } from 'api/services/navbar'

// --- Navbar ---
const Navbar = () => {
    const [expanded, setExpanded] = useState(false)

    // Retrieve price
    const [price, setPrice] = useState(0)
    useEffect(
        () =>
            getPriceVnyVsUsd()
                .then((v) => setPrice(v.vanity.usd))
                .catch(() => setPrice(0)),
        []
    )

    return (
        <BNavbar expand="md" variant="dark" expanded={expanded}>
            <Container className="d-inline-flex flex-row">
                <BNavbar.Toggle
                    aria-controls="navbarCollapse"
                    onClick={() => setExpanded(!expanded)}
                />
                <BNavbar.Collapse id="navbarCollapse">
                    <Nav variant="dark" className="text-center">
                        <Nav.Item>
                            <Nav.Link onClick={() => setExpanded(false)} as={NavLink} to="/" end>
                                <FormattedMessage id="navbar.link.home" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => setExpanded(false)}
                                as={NavLink}
                                to="/inventory"
                                end
                            >
                                <FormattedMessage id="navbar.link.inventory" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link onClick={() => setExpanded(false)} as={NavLink} to="/buy">
                                <FormattedMessage id="navbar.link.buy" />
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                            <Nav.Link
                                onClick={() => setExpanded(false)}
                                as={NavLink}
                                to="/marketplace"
                                end
                            >
                                <FormattedMessage id="navbar.link.marketplace" />
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </BNavbar.Collapse>

                {/* VNY Price string */}
                <div className="mx-auto ms-md-2 vny-price">
                    <FormattedMessage id="navbar.vny.price">
                        {(txt) => (
                            <span title={txt}>
                                <FormattedNumber
                                    value={price}
                                    // eslint-disable-next-line
                                    style="currency"
                                    currencyDisplay="symbol"
                                    currency="USD"
                                    minimumFractionDigits={8}
                                    maximumFractionDigits={8}
                                    className="me-2"
                                />
                            </span>
                        )}
                    </FormattedMessage>
                </div>

                {/* Wallet connect button */}
                <div className="me-auto me-md-0">
                    <Account />
                </div>
            </Container>
        </BNavbar>
    )
}

export default Navbar
