import { Outlet } from 'react-router-dom'

// Components
import IntlWrapper from 'components/IntlWrapper'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'

// --- AppLayout ---
const AppLayout = () => (
    <IntlWrapper>
        <Navbar />
        <Outlet />
        <Footer />
    </IntlWrapper>
)

export default AppLayout
