// Actions
export const LOGIN = 0x01
export const LOGOUT = 0x02

// Functional actions
export const doLogin = (secret) => ({
    type: LOGIN,
    value: secret,
})

export const doLogout = () => ({
    type: LOGOUT,
})
