import { Container, Spinner } from 'react-bootstrap'

// --- Loading ---
const Loading = ({ className = 'flex-grow-1' }) => (
    <Container fluid className={`${className} d-flex align-items-center justify-content-center`}>
        <Spinner animation="border" role="status" style={{ width: '3rem', height: '3rem' }}>
            <span className="visually-hidden">Loading...</span>
        </Spinner>
    </Container>
)

export default Loading
