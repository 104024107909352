import { Container, Row, Col } from 'react-bootstrap'
import { Outlet } from 'react-router-dom'

// --- BuyColLayout ---
export const BuyColLayout = () => (
    <>
        <Col />
        <Col md={8}>
            <Outlet />
        </Col>
        <Col />
    </>
)

// --- BuyPageLayout ---
export const BuyPageLayout = () => (
    <Container className="buy">
        <Row>
            <Outlet />
        </Row>
    </Container>
)
