import React from 'react'
import { ModalProvider } from '@pancakeswap/uikit'
import { HelmetProvider } from 'react-helmet-async'
import { Provider } from 'react-redux'
import { WalletStateProvider } from './context/Wallet'

import IntlWrapper from 'components/IntlWrapper'
import store from 'store'

const Providers: React.FC = ({ children }) => {
    return (
        <Provider store={store}>
            <WalletStateProvider>
                <HelmetProvider>
                    <IntlWrapper>
                        <ModalProvider>{children}</ModalProvider>
                    </IntlWrapper>
                </HelmetProvider>
            </WalletStateProvider>
        </Provider>
    )
}

export default Providers
