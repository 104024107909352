import { useContext } from 'react'
import { Skeleton } from 'antd'
import Blockies from 'react-blockies'
import { walletStore } from '../context/Wallet'

/**
 * Shows a blockie image for the provided wallet address
 * @param {*} props
 * @returns <Blockies> JSX Elemenet
 */

function Blockie(props) {
    const { state } = useContext(walletStore)
    const { address } = state
    if (!props.address && !address) return <Skeleton.Avatar active size={40} />

    return (
        <Blockies
            seed={props.currentWallet ? address.toLowerCase() : props.address.toLowerCase()}
            className="identicon"
            {...props}
        />
    )
}

export default Blockie
